import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

// colors
const primary = "#eb5160";
const secondary = "#43083c";
const tertiary = "#b2489a";
const textColor = "#353831";
const black = "#343a40";
const darkBlack = "rgb(36, 40, 44)";
const background = "#ffffff";
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "rgba(253, 200, 69, .5)";
const warningDark = "rgba(253, 200, 69, .7)";
const headingFontFamily = "'Anton', 'sans-serif'";
const subHeadingFontFamily = "'Roboto','Helvetica Neue','Arial','sans-serif',";

// border
const borderWidth = 2;
const borderColor = "rgba(0, 0, 0, 0.13)";

// breakpoints
const xl = 1920;
const lg = 1280;
const ipadPro = 1024;
const md = 960;
const ipad = 768;
const sm = 600;
const xs = 0;

// spacing
const spacing = 8;

const theme = createMuiTheme({
  palette: {
    primary: { main: primary },
    secondary: { main: secondary },
    tertiary: { main: tertiary },
    common: {
      black,
      darkBlack
    },
    warning: {
      light: warningLight,
      main: warningMain,
      dark: warningDark
    },
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    background: {
      default: background
    },
    spacing,
    text: {
      primary: textColor
    }
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xl,
      lg,
      ipadPro,
      md,
      ipad,
      sm,
      xs
    }
  },
  border: {
    borderColor: borderColor,
    borderWidth: borderWidth
  },
  overrides: {
    MuiExpansionPanel: {
      root: {
        position: "static"
      }
    },
    MuiTableCell: {
      root: {
        paddingLeft: spacing * 2,
        paddingRight: spacing * 2,
        borderBottom: `${borderWidth}px solid ${borderColor}`,
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing
        }
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: borderColor,
        height: borderWidth
      }
    },
    MuiButton: {
        fullWidth: {
        width: 'inherit',
      },
      containedSizeLarge: {
          textAlign: 'center'
      }
    },
    MuiPrivateNotchedOutline: {
      root: {
        borderWidth: borderWidth
      }
    },
    MuiListItem: {
      divider: {
        borderBottom: `${borderWidth}px solid ${borderColor}`
      }
    },
    MuiDialog: {
      paper: {
        width: "100%",
        maxWidth: 430,
        marginLeft: spacing,
        marginRight: spacing
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: darkBlack
      }
    },
    MuiGrid: {
      container: {
        justifyContent: "center"
      },
      "spacing-xs-5": {
        margin: "0 -20px"
      },
      "spacing-xs-3": {
        margin: "0 -12px"
      },
      "spacing-xs-2": {
        margin: "20px -8px"
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing
        }
      }
    }
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    h1: {
      fontFamily: headingFontFamily
    },
    h2: {
      fontFamily: headingFontFamily
    },
    h3: {
      fontFamily: headingFontFamily
    },
    h4: {
      fontFamily: subHeadingFontFamily
    },
    h5: {
      fontFamily: headingFontFamily
    }
  },
  shape: {
    borderRadius: 0,
  },
  shadows: [
    "none",
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    `5px 5px 0 ${primary}`,
  ]
});

export default responsiveFontSizes(theme);
